body[data-theme="light"] {
  @import "~/src/scss/light-slider.scss";

  .slider-tab {
    background-color: #d7caaa;
  }

  #resume {
    .main-badge {
      background: #4f69ae !important;
      color: white;
    }

    .experience-badge {
      background: #bdcbef !important;
      color: black;
    }
  }
}
